import React, { useContext } from "react";
import { StringsContext } from "../../strings";
import { Card } from "react-bootstrap";
import styles from "./403.module.scss";

const Error403 = () => {
  const { tl } = useContext(StringsContext);

  return (
    <>
      <Card className={styles.card}>
        <Card.Body className={styles.body}>
          <div className={styles.container}>
            <h1 className={styles.status}>{tl("403")}</h1>
            <p className={styles.message}>{tl("You do not have permission to access this page.")}</p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Error403;
