import React from "react";
import styles from "./404.module.scss";

const Error404 = () => {
  return (
    <div className={styles.container}>
      <div className="text-center">
        <h1 className={styles.status}>404</h1>
        <h2 className={styles.message}>Not Found</h2>
      </div>
    </div>
  );
};

export default Error404;
