import React, { Component } from "react";
import styles from "./Loader.module.scss";
import Spinner from "../Spinner";

class Loader extends Component {
  async componentWillUnmount() {
    await new Promise(resolve => setTimeout(() => resolve(), 5000));
  }

  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Spinner}>{Spinner}</div>
      </div>
    );
  }
}

export default Loader;
