import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import Popper from "popper.js";
import App from "./App";
import Store from "./store";
import * as serviceWorker from "./serviceWorker";
import { firebaseConfig } from "./config";

import { getAuth } from "firebase/auth"; // Firebase v9+
import { getDatabase } from "firebase/database"; // Firebase v9+
import { getStorage } from "firebase/storage"; // Firebase v9+
import { AuthProvider, DatabaseProvider, FirebaseAppProvider, StorageProvider, useFirebaseApp } from "reactfire";

import { Toaster } from "react-hot-toast";

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

function FirebaseComponents() {
  const app = useFirebaseApp();
  const database = getDatabase(app);
  const auth = getAuth(app);
  const storage = getStorage(app);
  return (
    <AuthProvider sdk={auth}>
      <DatabaseProvider sdk={database}>
        <StorageProvider sdk={storage}>
          <Store>
            <App />
            <Toaster
              toastOptions={{
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                  padding: "1rem",
                },
              }}
            />
          </Store>
        </StorageProvider>
      </DatabaseProvider>
    </AuthProvider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseComponents />
  </FirebaseAppProvider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
