import React, { useContext, useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { ref as dbRef, onValue } from "firebase/database";
import { Business, BusinessUser, Permissions } from "../types";
import { Context } from "../store";
import { activeAdminCheck } from "../shared/Utils";

type PermissionDetails = {
  permissions: Permissions;
  role: string;
  roleName: string;
};

const AirpayDefaultPermissions: Permissions = {
  dashboardFeedback: true,
  dashboardPaymentsReceived: true,
  dashboardSales: true,
  dashboardTopSellers: true,
  dashboardUnpaidInvoices: true,
  keypadAllowed: true,
  paymentAlipay: true,
  paymentCard: true,
  paymentCash: true,
  paymentOther: true,
  paymentPartial: true,
  repeatPayments: false, //
  sharing: true,
  manageBusiness: true,
  manageBusinessMode: true,
  viewReports: true,
  manageCustomers: true,
  manageItems: true,
  manageCategories: true,
  manageModifiers: true,
  manageDiscounts: true,
  manageSurcharges: true,
  manageSelfOrderingCards: true,
  manageUsers: true,
  priceOverride: true,
  customDiscounts: true,
  customSurcharges: true,
  refund: true,
  refundUnmatched: false,
  refundCashout: false,
  refundAdhoc: false,
  viewOtherInvoices: true,
  manageAccountingIntegration: true,
  managePermissions: true,
  paymentBluetooth: true,
  paymentUsb: false, //
  paymentTap: false, //
};

const DPMDefaultPermissions: Permissions = {
  ...AirpayDefaultPermissions,
  dashboardFeedback: true,
  dashboardPaymentsReceived: false,
  // dashboardSales: true,
  dashboardTopSellers: false,
  dashboardUnpaidInvoices: false,
  // keypadAllowed: true,
  paymentAlipay: false,
  // paymentCard: true,
  paymentCash: false,
  paymentOther: false,
  paymentPartial: false,
  repeatPayments: true,
  // sharing: true,
  manageBusiness: false,
  manageBusinessMode: false,
  // viewReports: true,
  manageCustomers: false,
  // manageItems: true,
  manageCategories: false,
  manageModifiers: false,
  manageDiscounts: false,
  manageSurcharges: false,
  manageSelfOrderingCards: false,
  manageUsers: false,
  priceOverride: false,
  customDiscounts: false,
  refund: false,
  viewOtherInvoices: true,
  manageAccountingIntegration: false,
  managePermissions: false,
  // paymentBluetooth: true,
  // paymentUsb: false,
  // paymentTap: false,
};

const SamsungDefaultPermissions: Permissions = {
  ...AirpayDefaultPermissions,
};

export const getPermissions = (
  appType: Business["allowedApp"],
  businessOverrides: Permissions = {},
  userOverrides: Permissions = {}
) => {
  const defaultPermissions = (() => {
    switch (appType) {
      default:
      case "airpay":
        return AirpayDefaultPermissions;
      case "dpm":
        return DPMDefaultPermissions;
      case "samsung":
        return SamsungDefaultPermissions;
    }
  })();
  return {
    ...defaultPermissions,
    ...businessOverrides,
    ...userOverrides,
  };
};

function getDefaultData() {
  return {
    permissions: {},
    role: "unknown",
    roleName: "Unknown",
  };
}

export function usePermissions() {
  const [state] = useContext(Context);
  const [status, setStatus] = useState<"loading" | "error" | "success">("loading");
  const [userData, setUserData] = useState<BusinessUser>();
  const [data, setData] = useState<PermissionDetails>(getDefaultData());
  const [error, setError] = useState<Error>();
  const database = useDatabase();

  const bid = state.currentBid;
  const uid = state.uid;
  const isOwner = state.business?.owner === uid;
  const userContainsBid = bid && state.user.businesses?.[bid];
  const doLoadUser = !!(bid && uid && state.business?.id === bid && (isOwner || userContainsBid));
  const isActiveAdmin = activeAdminCheck(state);
  const appType = state.business?.allowedApp || "airpay";

  useEffect(() => {
    if (!doLoadUser || isOwner || isActiveAdmin) {
      setUserData(undefined);
      setData(getDefaultData());
      return;
    }
    setStatus("loading");
    console.info(`Subscribing to user data ${uid}`);
    const ref = dbRef(database, `businessData/${bid}/users/${uid}/`);
    const unsubscribe = onValue(
      ref,
      snapshot => {
        let data = snapshot.val();
        if (!data) {
          setStatus("error");
          setError(new Error("No business user was found for this user."));
        }
        setUserData(data);
      },
      error => {
        setStatus("error");
        setError(error);
      }
    );
    return () => {
      console.info(`Unsubscribing from user data ${uid}`);
      unsubscribe();
    };
  }, [database, doLoadUser, isOwner, bid, uid, isActiveAdmin]);

  useEffect(() => {
    if (isOwner || isActiveAdmin || state.isReportingOnly) {
      const getRole = () => {
        if (state.isReportingOnly) {
          return "reportingonly";
        } else {
          return "owner";
        }
      };
      const getRoleName = () => {
        if (state.isReportingOnly) {
          return "Reporting Only";
        } else if (isActiveAdmin) {
          return "SYSTEM ADMIN";
        } else {
          return "Owner";
        }
      };
      setData({
        role: getRole(),
        roleName: getRoleName(),
        permissions: getPermissions(appType, state.business.permissions),
      });
      setStatus("success");
      return;
    }
    if (!userData) {
      return;
    }

    setData({
      role: userData.role,
      roleName: userData.roleName,
      permissions: getPermissions(appType, state.business.permissions, userData.permissions),
    });
    setStatus("success");
  }, [isOwner, appType, state.business.permissions, isActiveAdmin, userData, state.currentBid, state.isReportingOnly]);

  return { status, data, error };
}

export const PermissionsContext = React.createContext<PermissionDetails>({
  permissions: {},
  role: "unknown",
  roleName: "Unknown",
});
