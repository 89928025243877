import React, { Component } from "react";
import { footerBg } from "./Config";

class LayoutFooter extends Component {
  render() {
    // return null;
    // Removed footer for now - leaving in case we need to add it back for branding
    return (
      <footer className={`layout-footer footer bg-${footerBg}`}>
        <div className="container-fluid container-p-x pb-3">
          &copy;{" "}
          <a href="https://questps.com.au/" className="footer-link pt-3">
            Quest Payment Systems Pty. Ltd.
          </a>
        </div>
      </footer>
    );
  }
}

export default LayoutFooter;
