import React, { ReactElement, useContext, useEffect, useRef } from "react";
import {
  AccordionContext,
  AccordionToggleProps,
  Button,
  ButtonProps,
  Card,
  Modal,
  useAccordionToggle,
} from "react-bootstrap";
import { useFormState } from "react-hook-form";
import { Prompt, PromptProps, useHistory } from "react-router-dom";
import {
  serverTimestamp,
  ref as dbRef,
  get as dbGet,
  push as dbPush,
  update as dbUpdate,
  getDatabase,
  DatabaseReference,
} from "firebase/database";
import { BusinessSettings, PaymentType, Product, Timestamps } from "../../types";
import reactModal from "@prezly/react-promise-modal";
import { DragEndEvent } from "@dnd-kit/core";
import { firebaseConfig } from "../../config";
import { StringsContext } from "../../strings";
import { MainState } from "../../store";

export const formatCurrency = function (amount: number, currency: string = "AUD") {
  let formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 2,
  });
  const formatted = formatter.format(Math.abs(amount));
  return amount < 0 ? `(${formatted})` : formatted;
};

export const truncateDescription = function (description: string, max = 200) {
  description = description || "";
  return description.length > max ? description.substr(0, max) + "…" : description;
};

export const errorCard = function (error: string | React.ReactNode, title: string = "Something went wrong...") {
  if ((error as any)?.props?.children) {
    error = React.Children.map((error as any).props.children, child => {
      if (!child || !child.props) return child;
      return React.createElement(child.type, { ...{ ...child.props, className: "card-text" } });
    });
  } else {
    error = <Card.Text>{error}</Card.Text>;
  }
  return (
    <Card bg="danger" text="white">
      <Card.Header as="h5">{title}</Card.Header>
      <Card.Body>{error}</Card.Body>
    </Card>
  );
};

export const ToggleHeader: React.FC<AccordionToggleProps> = ({ children, eventKey, onClick }) => {
  const currentEventKey = useContext(AccordionContext);
  const accordionOnClick = useAccordionToggle(eventKey, onClick);

  const open = currentEventKey === eventKey;
  return (
    <Card.Header as="h5" onClick={accordionOnClick} className="cursor-pointer noselect">
      {children} <i className={`pull-right p-1 fas fa-fw fa-chevron-${open ? "up" : "down"}`}></i>
    </Card.Header>
  );
};

// per https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
export const getLuminance = (r: number, g: number, b: number) => {
  [r, g, b] = [r, g, b].map(v => {
    v /= 255; // convert to fraction
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
};

export const getContrast = (color1: string, color2: string) => {
  color1 = color1.replace("#", "");
  color2 = color2.replace("#", "");
  if (![3, 6].includes(color1.length) || ![3, 6].includes(color2.length)) {
    throw new Error("Invalid inputs");
  }
  if (color1.length === 3) {
    color1 = color1
      .split("")
      .map(v => `${v}${v}`)
      .join("");
  }
  if (color2.length === 3) {
    color2 = color2
      .split("")
      .map(v => `${v}${v}`)
      .join("");
  }
  let rgb1: [number, number, number] = [
    parseInt(color1.substr(0, 2), 16),
    parseInt(color1.substr(2, 2), 16),
    parseInt(color1.substr(4, 2), 16),
  ];
  let rgb2: [number, number, number] = [
    parseInt(color2.substr(0, 2), 16),
    parseInt(color2.substr(2, 2), 16),
    parseInt(color2.substr(4, 2), 16),
  ];
  let lum1 = getLuminance(...rgb1);
  let lum2 = getLuminance(...rgb2);
  let brightest = Math.max(lum1, lum2);
  let darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

export const saveButton = (
  saving: boolean,
  className: string = "",
  saveText: string = "Save",
  savingText: string = "Saving..."
) => (
  <Button variant="primary" type="submit" className={className} disabled={saving}>
    {saving ? (
      <>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span className="ml-2">{savingText}</span>
      </>
    ) : (
      saveText
    )}
  </Button>
);

export const FormAwareRoutePrompt = ({ when, message }: PromptProps) => {
  const { isDirty } = useFormState();

  return <Prompt when={isDirty || when} message={message} />;
};

export const updateTime = (obj: Timestamps, addCreated = false) => {
  if (addCreated) {
    obj.created = new Date().getTime();
    obj.serverCreated = serverTimestamp();
  }
  obj.modified = new Date().getTime();
  obj.serverModified = serverTimestamp();
};

export const deleteUndefined = (obj: any) => {
  for (const key in obj) {
    switch (typeof obj[key]) {
      case "undefined":
        delete obj[key];
        break;
      case "object":
        deleteUndefined(obj[key]);
        break;
    }
  }
};

export const createDeletionModal = (props: CreateModalProps) => {
  return createModal({
    rightButton: "Delete",
    rightButtonVariant: "danger",
    ...props,
  });
};

type CreateModalProps = {
  title?: string | ReactElement;
  body: string | ReactElement;
  allowOnHide?: boolean;
  onHideResult?: boolean | string;
  leftButton?: string;
  leftButtonVariant?: ButtonProps["variant"];
  leftButtonResult?: boolean | string;
  rightButton?: string;
  rightButtonVariant?: ButtonProps["variant"];
  rightButtonResult?: boolean | string;
  centered?: boolean;
  size?: "lg" | "sm" | "xl";
};
export const createModal = ({
  title,
  body,
  allowOnHide = false,
  onHideResult = false,
  rightButton = "OK",
  rightButtonVariant = "primary",
  rightButtonResult = true,
  leftButton = "Cancel",
  leftButtonVariant = "outline-secondary",
  leftButtonResult = false,
  centered = undefined,
  size = undefined,
}: CreateModalProps) => {
  const modal: Parameters<typeof reactModal>[0] = ({ show, onSubmit }) => (
    <Modal
      show={show}
      onHide={() => onSubmit(onHideResult)}
      backdrop={allowOnHide ? undefined : "static"}
      centered={centered}
      size={size}
    >
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {leftButton && (
          <Button variant={leftButtonVariant} onClick={() => onSubmit(leftButtonResult)}>
            {leftButton}
          </Button>
        )}
        {rightButton && (
          <Button variant={rightButtonVariant} onClick={() => onSubmit(rightButtonResult)}>
            {rightButton}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
  return modal;
};

export function getFirebaseId() {
  const database = getDatabase();
  return dbPush(dbRef(database, "/")).key;
}

export const firebaseLink = (...params: string[]) => {
  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || "");
  if (!firebaseConfig || !firebaseConfig.projectId) {
    console.error("Firebase config or projectId is not defined.");
    return;
  }
  window.open(`${firebaseConfig.databaseURL}/${params.join("/")}`, "_blank");
};

export function generateListPageHandleDragEnd(data: any[], ref: DatabaseReference) {
  return async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = active.data.current?.sortable.index;
      const newIndex = over.data.current?.sortable.index;
      const update: {
        [key: string]: number;
      } = {};
      const ascending = newIndex > oldIndex;
      let i = oldIndex;
      while (ascending ? i <= newIndex : i >= newIndex) {
        if (i === oldIndex) {
          update[`${data[i].id!}/order`] = newIndex;
        } else {
          update[`${data[i].id!}/order`] = i + (ascending ? -1 : 1);
        }
        i += ascending ? 1 : -1;
      }
      await dbUpdate(ref, update);
    }
  };
}

export function buildExportUrl(business_id: string, path: string, filename: string, token: string) {
  if (!business_id) {
    throw new Error("A business ID is required.");
  }
  const params = new URLSearchParams({
    print: "pretty",
    auth: token,
    download: filename,
  }).toString();

  return `${firebaseConfig.databaseURL}/businessData/${business_id}/${path}.json?${params}`;
}

const Timeout = (seconds: number) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), seconds * 1000);
  return controller;
};

export async function importData(business_id: string, path: string, token: string, data: any, update: boolean = true) {
  if (!business_id) {
    throw new Error("A business ID is required.");
  }
  const method = update ? "PATCH" : "PUT";
  const requestOptions = {
    method: method,
    body: data,
    signal: Timeout(60).signal,
  };
  const params = new URLSearchParams({
    auth: token,
    print: "silent",
  }).toString();

  const response = await fetch(
    `${firebaseConfig.databaseURL}/businessData/${business_id}/${path}.json?${params}`,
    requestOptions
  );
  const text = await response.text();
  if (!response.ok) {
    console.error(response.status, response.statusText, text);
    throw new Error(text || response.statusText);
  }
}

export async function emailPasswordReset(email: string) {
  const requestOptions = {
    method: "POST",
    signal: Timeout(10).signal,
  };
  const params = new URLSearchParams({
    email,
  }).toString();

  const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/passwordResetRequest?${params}`, requestOptions);
  const json = await response.json();
  if (!response.ok) {
    console.error(response.status, response.statusText, json.error);
    throw new Error(json.error || response.statusText);
  }
}

export class JsonError<T = any> extends Error {
  result?: T;
}

export async function jsonFunctionsCall<T = any>(endpoint: string, data: any) {
  return jsonCall<T>("POST", `${process.env.REACT_APP_FUNCTIONS_URL}${endpoint}`, data);
}

export async function jsonCall<T = any>(method: "GET" | "POST", endpoint: string, data?: any, timeout = 15) {
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
    signal: Timeout(timeout).signal,
  };
  const response = await fetch(endpoint, requestOptions);
  const jsonResult = await response.json();
  if (!response.ok) {
    console.error(response.status, response.statusText, jsonResult);
    const error = new JsonError(response.statusText);
    error.result = jsonResult;
    throw error;
  }
  return jsonResult as T;
}

export const appendQuery = (baseUrl: URL, query: { [key: string]: string }) => {
  const params = new URLSearchParams(baseUrl.search);
  Object.entries(query).forEach(([key, value]) => params.append(key, value));
  return new URL(`?${params.toString()}`, baseUrl).toString();
};

export const createReportingApiUrl = (endpoint: string, query: { [key: string]: string }) => {
  const baseUrl = new URL(endpoint, process.env.REACT_APP_REPORTING_API);
  return appendQuery(baseUrl, query);
};

export async function httpGetJson(url: string) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    signal: Timeout(10).signal,
  };

  const response = await fetch(url, requestOptions);
  const jsonResult = await response.json();
  if (!response.ok) {
    console.error(response.status, response.statusText, jsonResult);
    throw new Error(jsonResult?.error || response.statusText);
  }
  return jsonResult;
}

export const computeElasticSort = (order: string): { sort: string } | { sort_desc: string } | {} => {
  if (!order) {
    return {};
  }

  return order.startsWith("-") ? { sort_desc: order.slice(1) } : { sort: order };
};

export const abandonEarlierPromises = <T extends any>(onAbandoned?: () => T) => {
  let lastPromise: Promise<T> | undefined;
  return async (callback: () => Promise<T>) => {
    const promise = callback();
    lastPromise = promise;
    const result = await promise;
    // Compare current and previous promise so we only return
    // results if a new call hasn't been made.
    if (lastPromise === promise) {
      return result;
    }

    // Otherwise, there's a more recent request, so this promise
    // is abandoned
    if (!onAbandoned) {
      throw new Error("abandoned");
    }
    return onAbandoned();
  };
};

export function debounce<T extends (...args: any) => void>(callback: T, delay = 300) {
  let timer: NodeJS.Timeout | undefined;
  return (...params: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => callback(...params), delay);
  };
}

export const downloadReceipt = (receipt: string, filename: string) => {
  let newBlob = new Blob([receipt], { type: "text/plain" });
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(newBlob, filename);
    return;
  }
  const data = window.URL.createObjectURL(newBlob);
  let link = document.createElement("a");
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(data);
  }, 500);
};

export async function clearFromProducts(bid: string, key: "categories" | "modifierSets", id: string) {
  const database = getDatabase();
  const ref = dbRef(database, `businessData/${bid}/products/`);
  const products = await dbGet(ref);
  const pids: string[] = [];
  products.forEach(snap => {
    const product = snap.val() as Product;
    if (product[key]?.[id]) {
      pids.push(snap.key!);
    }
  });
  if (pids.length) {
    console.info(`Removing ${key} link from ${pids.length} products.`);
    await dbUpdate(ref, {
      ...Object.fromEntries(pids.map(pid => [`${pid}/${key}/${id}`, null])),
    });
  }
}

export function buttonise(f: Function) {
  return {
    role: "button",
    onClick: () => f(),
    onKeyUp: (e: React.KeyboardEvent) => {
      if (["Enter", "Space"].includes(e.key)) {
        f();
      }
    },
  };
}

export const getTimezone = (businessSettings: BusinessSettings): string => {
  if (businessSettings && businessSettings.timezone) {
    return businessSettings.timezone;
  }
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

interface SectionHeaderProps {
  header_name: string;
  button_name: string;
  path?: string;
  onClick?: () => void;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ header_name, button_name, path = null, onClick }) => {
  const history = useHistory();
  const { tl } = useContext(StringsContext);

  return (
    <Card.Body>
      <Button
        className="btn btn-primary float-right"
        onClick={path !== null ? () => history.push(path) : onClick ? onClick : () => {}}
      >
        {tl("Add %s", button_name)}
      </Button>
      <Card.Title as="h2" className="mb-0">
        {tl(header_name)}
      </Card.Title>
    </Card.Body>
  );
};

function convertArrayOfObjectsToCSV(data: Record<string, any>[], customOrder: string[] = []): string {
  let csv = "";

  // Get the headers
  const headers =
    customOrder.length > 0
      ? customOrder
      : Object.keys(data[0]).map(header => header.charAt(0).toUpperCase() + header.slice(1));

  // Add header row
  csv += headers.join(",") + "\n";

  // Add data rows
  data.forEach(item => {
    const values = headers.map(header => {
      const value = item[header.toLowerCase()];
      return typeof value === "string" && value.includes(",") ? `"${value}"` : value;
    });
    csv += values.join(",") + "\n";
  });

  return csv;
}

export const downloadCSV = (data: Record<string, any>[], filename: string, customOrder: string[] = []): void => {
  const csv = convertArrayOfObjectsToCSV(data, customOrder);

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const paymentTypeName = (type: PaymentType | string) => {
  switch (type) {
    case "no_charge":
      return "No Charge";
    case "":
      return "-";
    default:
      return type;
  }
};

export const PaymentResult = ({ code, message }: { code?: string; message?: string }) => {
  if (!message) {
    return <></>;
  }
  if (!code) {
    return <>{message}</>;
  }
  return (
    <>
      {message} <span className="badge badge-pill badge-secondary">{code}</span>
    </>
  );
};

export function useIsComponentMounted() {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return isComponentMounted;
}

export const adminCheck = (state: MainState) => {
  return !!state.token?.claims.admin;
};

export const superAdminCheck = (state: MainState) => {
  return !!state.token?.claims.superAdmin;
};

export const activeAdminCheck = (state: MainState, requireSuperAdmin = false) => {
  if (requireSuperAdmin && !state.token?.claims.superAdmin) {
    return false;
  }
  return adminCheck(state) && state.token?.claims.adminAccess?.b === state.currentBid;
};
