import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDatabase } from "reactfire";
import { ref as dbRef, get as dbGet, set as dbSet, serverTimestamp } from "firebase/database";
import { Context } from "../../store";
import Markdown from "react-markdown";
import { Agreement } from "../../types";
import InlineLoader from "../../shared/InlineLoader";

export default function AgreementModal({ logout }: { logout: () => void }) {
  const [state] = useContext(Context);
  const database = useDatabase();
  const [agreementData, setAgreementData] = useState<Agreement | null>(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const agreements = state.business?.agreements || {};
  const [key, agreement] = Object.entries(agreements)
    .filter(([k, a]) => !a.acceptedAt)
    .sort(([k1, a1], [k2, a2]) => a1.requestedAt - a2.requestedAt)[0] || [undefined, undefined];

  const loadAgreementData = useCallback(() => {
    if (key) {
      setError("");
      setLoading(true);
      const agreementRef = dbRef(database, `agreements/${key}`);
      dbGet(agreementRef)
        .then(snapshot => {
          setAgreementData(snapshot.val());
          setLoading(false);
        })
        .catch(e => {
          setError(String(e));
          setLoading(false);
        });
    } else {
      setChecked(false);
      setAgreementData(null);
    }
  }, [key, database]);

  const agree = () => {
    setAgreementData(null);
    setChecked(false);
    setLoading(true);
    const acceptedAtRef = dbRef(database, `businesses/${state.currentBid}/agreements/${key}/acceptedAt`);
    dbSet(acceptedAtRef, serverTimestamp())
      .then(() => {
        setLoading(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    loadAgreementData();
  }, [key, loadAgreementData]);

  useEffect(() => {
    if (key && agreementData && !agreement?.firstSeenAt) {
      const firstSeenAtRef = dbRef(database, `businesses/${state.currentBid}/agreements/${key}/firstSeenAt`);
      dbSet(firstSeenAtRef, serverTimestamp()).catch(console.error);
    }
  }, [agreementData, key, agreement?.firstSeenAt, database, state.currentBid]);

  if (!key || !agreementData) {
    return null;
  }

  return (
    <Modal show={true} backdrop={"static"} centered={true} scrollable={true} size="sm">
      <Modal.Header>
        <Modal.Title>{agreementData?.title || "Important Information"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <InlineLoader />}
        {error && (
          <div>
            <p>Something went wrong loading the information.</p>
            <p>
              <Button variant="link" onClick={loadAgreementData}>
                Try Again
              </Button>
            </p>
          </div>
        )}
        {!loading && !error && (
          <>
            <Markdown>{agreementData.content}</Markdown>
            {agreementData.checkbox && (
              <>
                <hr />
                <Form.Group controlId="agreementAgreed">
                  <Form.Check
                    custom
                    type="checkbox"
                    label={agreementData.checkbox}
                    checked={checked}
                    onChange={e => setChecked(e.target.checked)}
                  />
                </Form.Group>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button variant="outline-primary" onClick={logout}>
          Sign out
        </Button>
        <Button variant="primary" onClick={agree} disabled={!!agreementData.checkbox && !checked}>
          {agreementData.continue || "Continue"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
