// Guard against missing environmental vars
[
  "REACT_APP_VERSION",
  "REACT_APP_NAME",
  "REACT_APP_FLAVOUR",
  "REACT_APP_REPORTING_API",
  "REACT_APP_FUNCTIONS_URL",
  "REACT_APP_FIREBASE_CONFIG",
].forEach(k => {
  if (process.env[k] === undefined) {
    throw new Error(`Missing required environment variable ${k}. Quitting.`);
  }
});

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);
