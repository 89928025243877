import React, { useContext, useEffect, useRef } from "react";
import Sidenav from "../../vendor/libs/sidenav";
import { sidenavBg } from "./Config";
import { actions, Context, MainState } from "../../store";

import { getPath } from "../../routes";

import "./LayoutSidenav.scss";
import { StringsContext } from "../../strings";
import { PermissionsContext } from "../../permissions";
import { useHistory } from "react-router-dom";
import { ImageLoader } from "../FormFields/ImageLoader";
import { Permissions } from "../../types";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { activeAdminCheck } from "../Utils";

const LayoutSidenav = ({
  orientation = "vertical",
  location,
  sidenavToggle,
}: {
  orientation: "vertical" | "horizontal";
  location: "string";
  sidenavToggle: boolean;
}) => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const { permissions, role, roleName } = useContext(PermissionsContext);
  const { tl } = useContext(StringsContext);

  const ps = useRef(null);

  const logo = state.business.logo_thumbnail || state.business.logo || false;

  useEffect(() => {
    if (ps.current) {
      (ps.current as HTMLDivElement).scrollTop = (window as any).sidebarScrollTop || 0;
    }
  }, [state.currentBid]);

  const handleScroll = _.debounce(() => {
    if (ps.current) {
      (window as any).sidebarScrollTop = (ps.current as HTMLDivElement).scrollTop;
    }
  }, 250);

  function layoutSidenavClasses() {
    let bg = sidenavBg;

    if (orientation === "horizontal" && (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1)) {
      bg = bg.replace(" sidenav-dark", "").replace(" sidenav-light", "").replace("-darker", "").replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (orientation !== "horizontal" ? "layout-sidenav" : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  }

  /**
   * @param {Business} business
   * @param {User} user
   */
  const businessPanel = (business: MainState["business"] & { users?: any }, roleName: string) => {
    if (!business) {
      return "";
    }
    if (!roleName) {
      roleName = business.owner === state.uid ? "Owner" : business.users?.[state.uid!]?.roleName; // this is incorrect so this needs to be changed
    }
    return (
      <>
        <ImageLoader
          className="app-brand-logo business-image"
          src={logo}
          alt="business logo"
          blurhash={business.blurhash}
          defaultImage={
            <div className="app-brand-logo business-image">
              <i className={`fas ${state.isReportingOnly ? "fa-city" : "fa-building"} fa-lg`}></i>
            </div>
          }
        />
        <div className="app-brand-text business-title ml-2">
          <div className="business-name mb-1">{state.isReportingOnly ? "All Businesses" : business?.name}</div>
          {roleName && <div className="business-rolename">{roleName}</div>}
        </div>
      </>
    );
  };

  const switchToMyBusiness = () => {
    if (state.businessIds.length === 1) {
      return history.push(`/b/${state.businessIds[0]}`);
    } else {
      return dispatch({
        type: actions.SHOW_BUSINESS_PICKER,
      });
    }
  };

  const showInvoices =
    ["invoicing", "hybrid", "hospitality"].includes(state.business.businessMode!) || state.isReportingOnly;
  const showManageMenu = (
    [
      "manageCustomers",
      "manageItems",
      "manageCategories",
      "manageDiscounts",
      "manageSurcharges",
      "manageModifiers",
    ] as (keyof Permissions)[]
  ).some(p => permissions[p]);
  const showBusinessMenu =
    role === "owner" ||
    (["manageBusiness", "manageBusinessMode", "manageUsers"] as (keyof Permissions)[]).some(p => permissions[p]);

  interface SectionItemProps {
    to: string;
    exact: true;
    label: string;
    icon: string;
    lock?: boolean;
    show: boolean;
  }

  interface SidebarItemProps {
    id: string;
    header: string;
    hideHeader?: boolean;
    lock?: boolean;
    show: boolean;
    listItems: (React.ReactNode | SectionItemProps)[];
  }

  const SidebarItems: SidebarItemProps[] = [
    {
      id: "administration-label",
      header: tl("Administration"),
      show: !state.isReportingOnly && !!state.token?.claims.admin,
      listItems: [
        !state.businessIds.includes(state.currentBid!) && (
          <Button role="navigation" variant="link" className="sidenav-item d-block" onClick={switchToMyBusiness}>
            <div className="sidenav-link">
              <i className="sidenav-icon fas fa-fw fa-sync-alt"></i>
              <div>{tl("Switch to my Business")}</div>
            </div>
          </Button>
        ),
        {
          to: getPath("businessSearch"),
          exact: true,
          icon: "fas fa-fw fa-search",
          label: tl("Business Search"),
          show: true,
        },
      ],
    },
    {
      id: "home-and-invoices-label",
      header: tl("Home & Invoices"),
      hideHeader: true,
      show: true,
      listItems: [
        {
          to: getPath("home"),
          exact: true,
          icon: "fas fa-fw fa-home",
          label: tl("Home"),
          show: !state.isReportingOnly,
        },
        {
          to: getPath("invoices"),
          exact: false,
          icon: "fas fa-fw fa-file-invoice-dollar",
          label: tl("Invoices"),
          show: showInvoices,
        },
        {
          to: getPath("paid"),
          exact: false,
          icon: "fas fa-fw fa-receipt",
          label: tl("Paid"),
          show: true,
        },
      ],
    },
    {
      id: "reports-label",
      header: tl("Reports"),
      lock: !permissions.viewReports,
      show: permissions.viewReports || activeAdminCheck(state),
      listItems: [
        {
          to: getPath("reportSummary"),
          exact: false,
          icon: "fas fa-fw fa-chart-line",
          label: tl("Summary"),
          show: true,
        },
        {
          to: getPath("reportItemSales"),
          exact: false,
          icon: "fas fa-fw fa-chart-bar",
          label: tl("Item Sales"),
          show: !state.isReportingOnly,
        },
        {
          to: getPath("payments"),
          exact: false,
          icon: "fas fa-fw fa-credit-card",
          label: tl("Payments"),
          show: true,
        },
      ],
    },
    {
      id: "inventory-and-customers-label",
      header: permissions.manageCustomers ? tl("Inventory & Customers") : tl("Inventory"),
      lock: !showManageMenu,
      show: !state.isReportingOnly && (showManageMenu || activeAdminCheck(state)),
      listItems: [
        {
          to: getPath("contacts"),
          exact: false,
          icon: `fas fa-fw ${tl("fa-address-book")}`,
          label: tl("Customers"),
          lock: !permissions.manageCustomers,
          show: permissions.manageCustomers || activeAdminCheck(state),
        },
        {
          to: getPath("products"),
          exact: false,
          icon: "fas fa-fw fa-shopping-bag",
          label: tl("Products"),
          lock: !permissions.manageItems,
          show: permissions.manageItems || activeAdminCheck(state),
        },
        {
          to: getPath("categories"),
          exact: false,
          icon: "fas fa-fw fa-folder-open",
          label: tl("Categories"),
          lock: !permissions.manageCategories,
          show: permissions.manageCategories || activeAdminCheck(state),
        },
        {
          to: getPath("discounts"),
          exact: false,
          icon: "fas fa-fw fa-badge-percent",
          label: tl("Discounts"),
          lock: !permissions.manageDiscounts,
          show: permissions.manageDiscounts || activeAdminCheck(state),
        },
        {
          to: getPath("surcharges"),
          exact: false,
          icon: "fas fa-fw fa-percentage",
          label: tl("Surcharges"),
          lock: !permissions.manageSurcharges,
          show: permissions.manageSurcharges || activeAdminCheck(state),
        },
        {
          to: getPath("modifiers"),
          exact: false,
          icon: "fas fa-fw fa-search-plus",
          label: tl("Modifiers"),
          lock: !permissions.manageModifiers,
          show: permissions.manageModifiers || activeAdminCheck(state),
        },
      ],
    },
    {
      id: "business-settings-label",
      header: tl("Business Settings"),
      lock: !showBusinessMenu,
      show: !state.isReportingOnly && (showBusinessMenu || activeAdminCheck(state)),
      listItems: [
        {
          to: getPath("businessDetails"),
          exact: true,
          icon: "fas fa-fw fa-building",
          label: tl("Business Details"),
          lock: !permissions.manageBusiness,
          show: permissions.manageBusiness || activeAdminCheck(state),
        },
        {
          to: getPath("businessMode"),
          exact: true,
          icon: "fas fa-fw fa-suitcase",
          label: tl("Business Mode"),
          lock: !permissions.manageBusinessMode,
          show: permissions.manageBusinessMode || activeAdminCheck(state),
        },
        {
          to: getPath("businessUsers"),
          exact: false,
          icon: "fas fa-fw fa-user-friends",
          label: tl("Additional Users"),
          lock: !permissions.manageUsers,
          show: permissions.manageUsers || activeAdminCheck(state),
        },
        {
          to: getPath("permissions"),
          exact: false,
          icon: "fas fa-fw fa-suitcase",
          label: `${tl("Roles")} & ${tl("Features")}`,
          lock: !permissions.managePermissions,
          show: (permissions.managePermissions && role === "owner") || activeAdminCheck(state),
        },
      ],
    },
    {
      id: "account-data-label",
      header: tl("Account & Data"),
      show: !state.isReportingOnly,
      listItems: [
        {
          to: getPath("security"),
          exact: true,
          icon: "fas fa-fw fa-user-shield",
          label: tl("Account Security"),
          show: state.currentBid && !activeAdminCheck(state),
        },
        {
          to: getPath("data"),
          exact: true,
          icon: "fas fa-fw fa-database",
          label: tl("Data Management"),
          lock: role !== "owner",
          show: role === "owner" || activeAdminCheck(state),
        },
      ],
    },
    {
      id: "footer-sidenav-label",
      header: tl("Information"),
      hideHeader: true,
      show: true,
      listItems: [
        <Sidenav.Link
          href={process.env.REACT_APP_HELP_LINK}
          target="_blank"
          icon="fas fa-fw fa-question-circle"
          rel="noreferrer"
        >
          Help <i className="fas fa-fw fa-external-link-alt"></i>
        </Sidenav.Link>,
        {
          to: getPath("about"),
          exact: true,
          icon: "fas fa-fw fa-info",
          label: `${tl("About")} ${tl("Airpay POS")}`,
          show: true,
        },
      ],
    },
  ];

  return (
    <Sidenav orientation={orientation} className={layoutSidenavClasses()}>
      {/* Inner */}
      <div className="app-brand business-panel">{businessPanel(state.business, roleName)}</div>
      <Sidenav.Divider className="mt-0" />
      <div className="sidenav-inner" ref={ps} onScroll={handleScroll}>
        {(state.business?.businessMode || state.token?.claims.admin || state.isReportingOnly) &&
          SidebarItems.map((section, index: number) => {
            if (!section.show) {
              return null;
            }
            return (
              <nav key={index} role="navigation" className="w-100" aria-labelledby={section.id}>
                <Sidenav.Header
                  id={section.id}
                  className={`small font-weight-semibold${section.hideHeader ? " d-none" : ""}`}
                >
                  {section.header} {section.lock && <i className="fas fa-lock-open ml-1"></i>}
                </Sidenav.Header>
                <ul className="list-unstyled list-inline">
                  {section.listItems.map((sectionItem, subIndex: number) => {
                    if (!sectionItem) {
                      return null;
                    }

                    if (React.isValidElement(sectionItem)) {
                      return <li key={`${index}.${subIndex}`}>{sectionItem}</li>; // If it's a ReactNode, return it as is
                    }

                    const { to, exact, label, icon, lock, show } = sectionItem as SectionItemProps;

                    if (!show) {
                      return null;
                    }

                    return (
                      <li key={`${index}.${subIndex}`}>
                        <Sidenav.RouterLink role="navigation" to={to} exact={exact} icon={icon}>
                          {label} {lock && <i className="fas fa-lock-open ml-1"></i>}
                        </Sidenav.RouterLink>
                      </li>
                    );
                  })}
                </ul>
                <Sidenav.Divider />
              </nav>
            );
          })}
        <div className="quest-logo">
          <a href="https://questps.com.au/" target="_blank" rel="noreferrer">
            <img
              src={`/assets/img/${process.env.REACT_APP_SIDE_LOGO || "quest-logo.png"}`}
              className="img-fluid"
              alt="Quest Payment Systems"
            />
          </a>
        </div>
      </div>
    </Sidenav>
  );
};

export default LayoutSidenav;
