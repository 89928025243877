import React, { useContext, useEffect, useRef, useState } from "react";
import Router from "./shared/Router";

import { useAuth, useDatabase, useSigninCheck } from "reactfire";
import { useDatabaseObjectData, clearKeepAliveListeners } from "./hooks/firebase";
import { ref as dbRef, get as dbGet } from "firebase/database";
import { Settings as LuxonSettings } from "luxon";

import { Context, actions } from "./store";
import Loader from "./shared/Loader";

import { defaultStrings, DPMStrings, HospitalityModeStrings, SamsungStrings, StringsProvider } from "./strings";

import { PermissionsContext, usePermissions } from "./permissions";
import BusinessSwitcher from "./components/common/BusinessSwitcher";
import AgreementModal from "./components/common/AgreementModal";

require(`./theme/styles/${process.env.REACT_APP_FLAVOUR}.scss`);

function UserDataLoader({ uid, setError }) {
  const [, dispatch] = useContext(Context);
  const database = useDatabase();
  const userRef = dbRef(database, `users/${uid}`);
  const {
    status,
    data: userData,
    error,
  } = useDatabaseObjectData(userRef, {
    idField: "id",
  });

  useEffect(() => {
    if (status === "success") {
      console.log("Received updated user data: ", userData);
      dispatch({
        type: actions.SET_USER_DATA,
        uid,
        user: userData,
      });
    } else if (status === "error") {
      console.error(error);
      setError(`Unable to load user data. ${String(error)}`);
    }
  }, [status, userData, uid, dispatch, error, setError]);
  return null;
}

function BusinessLoader({ bid, setError }) {
  const [, dispatch] = useContext(Context);
  const database = useDatabase();
  const businessRef = dbRef(database, `businesses/${bid}`);
  const {
    status: status1,
    data: businessData,
    error: error1,
  } = useDatabaseObjectData(businessRef, {
    idField: "id",
  });
  const businessSettingsRef = dbRef(database, `businessData/${bid}/settings`);
  const {
    status: status2,
    data: businessSettings,
    error: error2,
  } = useDatabaseObjectData(businessSettingsRef, {
    idField: "id",
    errorOnEmpty: false,
  });

  useEffect(() => {
    if (status1 === "success") {
      console.log("Received updated business data: ", businessData);

      if (!businessData) {
        setError(
          `The business you're looking for seems to be missing. Check the URL and try again, or otherwise contact support.`
        );
        return;
      }

      if (businessData.timezone) {
        LuxonSettings.defaultZoneName = businessData.timezone;
      } else {
        LuxonSettings.defaultZoneName = "local";
      }

      dispatch({
        type: actions.SET_BUSINESS_DATA,
        bid,
        business: businessData,
      });
    } else if (status1 === "error") {
      console.error(error1);
      setError(`Unable to load business data. ${String(error1)}`);
    }
  }, [status1, businessData, bid, dispatch, error1, setError]);

  useEffect(() => {
    if (status2 === "success") {
      console.log("Received updated business settings: ", businessSettings);

      dispatch({
        type: actions.SET_BUSINESS_SETTINGS,
        bid,
        businessSettings: businessSettings,
      });
    } else if (status2 === "error") {
      console.error(error2);
      setError(`Unable to load business settings data. ${String(error2)}`);
    }
  }, [status2, businessSettings, bid, dispatch, setError, error2]);
  return null;
}

function App(props) {
  const [state, dispatch] = useContext(Context);

  const auth = useAuth();
  const database = useDatabase();
  const businessIdGetters = useRef([]);
  const [currentStrings, setCurrentStrings] = useState(defaultStrings);
  const [applicationError, setApplicationError] = useState();

  const { status, data: signinCheckResult, error } = useSigninCheck();
  const { status: permsStatus, data: permissionsData, error: permsError } = usePermissions();
  const user = signinCheckResult?.user;
  const uid = user?.uid;
  const bid = state?.currentBid;

  const logout = async e => {
    if (e) {
      e.preventDefault();
    }
    clearKeepAliveListeners();
    delete window.sidebarScrollTop;
    dispatch({
      type: actions.LOGOUT,
    });
    await auth.signOut();
  };

  useEffect(() => {
    if (user && Object.keys(state.user).length !== 0) {
      let tokenExpired = false;
      if (state.token && state.user.minTokenTime) {
        const issuedAt = new Date(state.token.issuedAtTime).getTime() / 1000;
        tokenExpired = issuedAt < state.user.minTokenTime;
      }
      if (!state.token || tokenExpired) {
        if (tokenExpired) {
          console.log("Forcibly refreshing token due to current one being old.");
        }
        user
          .getIdTokenResult(true)
          .then(idTokenResult => {
            console.log("Received updated id token: ", idTokenResult);
            dispatch({
              type: actions.SET_USER_TOKEN,
              token: idTokenResult,
            });
          })
          .catch(e => {
            if (tokenExpired) {
              clearKeepAliveListeners();
              delete window.sidebarScrollTop;
              dispatch({
                type: actions.LOGOUT,
              });
              auth.signOut().catch(() => {});
            } else {
              setApplicationError(`Something went wrong authenticating. ${e.message}`);
            }
          });
      }
    }
  }, [state.user, state.token, user, dispatch, auth]);

  useEffect(() => {
    let newStrings;
    switch (state.business.allowedApp) {
      default:
      case "airpay":
        newStrings = defaultStrings;
        break;
      case "dpm":
        newStrings = DPMStrings;
        break;
      case "samsung":
        newStrings = SamsungStrings;
        break;
    }
    if (state.business.businessMode === "hospitality") {
      newStrings = {
        ...newStrings,
        ...HospitalityModeStrings,
      };
    }
    setCurrentStrings(newStrings);
  }, [state.business.allowedApp, state.business.businessMode]);

  useEffect(() => {
    for (const bid of state.businessIds) {
      if (bid !== state.currentBid && !state.businesses[bid] && !businessIdGetters.current.includes(bid)) {
        const businessRef = dbRef(database, `businesses/${bid}`);
        businessIdGetters.current.push(bid);
        dbGet(businessRef).then(snapshot => {
          const businessData = snapshot.val();
          const i = businessIdGetters.current.indexOf(bid);
          if (i !== -1) {
            businessIdGetters.current.splice(i, 1);
          }
          dispatch({
            type: actions.SET_BUSINESS_DATA,
            bid,
            business: businessData,
          });
        });
      }
    }
  }, [state.businessIds, state.businesses, state.currentBid, database, dispatch]);

  useEffect(() => {
    const removeLoadingClass = () => {
      document.documentElement.classList.remove("app-loading");
    };
    // Remove splash screen
    const splashScreen = document.querySelector(".app-splash-screen");
    if (splashScreen) {
      splashScreen.style.opacity = 0;
      setTimeout(() => {
        if (splashScreen && splashScreen.parentNode) {
          splashScreen.parentNode.removeChild(splashScreen);
        }
        removeLoadingClass();
      }, 300);
    } else {
      removeLoadingClass();
    }
  }, []);

  if (applicationError) {
    const logoutReload = async () => {
      await logout();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    };
    return (
      <div className="container h-100" style={{ position: "absolute", margin: "auto", left: 0, right: 0 }}>
        <div className="h-100 row align-items-center">
          <div className="card col text-white bg-danger mb-3">
            <div className="card-body">
              <h5 className="card-title">{currentStrings["Airpay POS"]} Error</h5>
              <p className="card-text">
                Something went wrong with {currentStrings["Airpay POS"]}. Please reload the page to try again. The full
                error is shown below.
              </p>
              <p className="card-text">
                <code className="text-white">{applicationError}</code>
              </p>
            </div>
            <div className="card-footer">
              <button className="btn btn-dark" onClick={logoutReload}>
                Logout and reload the page.
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (status === "error") {
    console.error(error);
    return <div>Something went wrong... please reload the site and try again.</div>;
  }

  if (permsStatus === "error") {
    console.error(permsError);
    return <div>Something went wrong... please reload the site and try again.</div>;
  }

  if (status === "loading") {
    return <Loader />;
  }

  const showRouter = !uid || bid;

  return (
    <>
      {uid && <UserDataLoader uid={uid} setError={setApplicationError} />}
      {bid && !state.isReportingOnly && <BusinessLoader bid={bid} setError={setApplicationError} />}
      <BusinessSwitcher />
      <AgreementModal logout={logout} />

      {permsStatus === "loading" && bid && <Loader />}

      {(permsStatus === "success" || !bid) && (
        <PermissionsContext.Provider value={permissionsData}>
          <StringsProvider strings={currentStrings}>
            {!showRouter && <Loader />}
            {showRouter && <Router logout={logout} />}
          </StringsProvider>
        </PermissionsContext.Provider>
      )}
    </>
  );
}

export default App;
