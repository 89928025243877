import React, { Component } from "react";
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";
import layoutHelpers from "./helpers";
import "./Layout2.scss";

class Layout2 extends Component {
  closeSidenav(e) {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  componentDidMount() {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    return (
      <div className="position-relative">
        <a
          role="button"
          className="position-absolute btn btn-primary sr-only sr-only-focusable top-0 start-50 translate-middle z-2 py-2 px-4 mt-2 skip-navigation-btn"
          href="#main-content"
        >
          Skip navigation
        </a>
        <div className="position-absolute layout-wrapper layout-2 z-1">
          <div className="layout-inner">
            <LayoutSidenav {...this.props} />

            <div role="main" className="layout-container">
              <LayoutNavbar {...this.props} />

              <div id="main-content" className="layout-content">
                <div className="container-fluid flex-grow-1 container-p-y">{this.props.children}</div>

                <LayoutFooter {...this.props} />
              </div>
            </div>
          </div>
          <div className="layout-overlay" onClick={this.closeSidenav} aria-hidden="true"></div>
        </div>
      </div>
    );
  }
}

export default Layout2;
