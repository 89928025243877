import React, { useEffect, useState } from "react";
import { BlurhashCanvas } from "react-blurhash";
import { useIsComponentMounted } from "../Utils";

type ImageLoaderProps = {
  src: any;
  alt: string;
  blurhash: string | undefined;
  className?: string;
  defaultImage: JSX.Element;
};

export const ImageLoader = ({ src, alt, className, blurhash, defaultImage }: ImageLoaderProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const isMounted = useIsComponentMounted();

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      if (img.complete) {
        setImageLoaded(true);
      } else {
        img.onload = () => {
          if (isMounted.current) {
            setImageLoaded(true);
          }
        };
      }
    }
  }, [src, isMounted]);

  if (src && imageLoaded) return <img className={className} src={src} alt={alt} />;
  if (src && blurhash) return <BlurhashCanvas className={className} hash={blurhash} />;
  return defaultImage;
};
